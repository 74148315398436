import React from "react";

import MainContent from "./MainContent";

function App() {
  return (
    <main>
      <MainContent />
    </main>
  );
}

export default App;
